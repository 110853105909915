//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  onMounted,
  ref,
  shallowRef,
  watch,
  toRaw,
  nextTick,
} from '@nuxtjs/composition-api';
import isEqual from 'lodash/isEqual';

// Chart.JS (https://www.chartjs.org/docs/3.9.1/)
import {
  Chart,
  Tooltip,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  PieController,
  ArcElement,
  Filler
} from 'chart.js';

// Register components required per chart type (https://www.chartjs.org/docs/3.9.1/getting-started/integration.html)
Chart.register(
  Tooltip,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  PieController,
  ArcElement,
  Filler
)

export default defineComponent({
  name: 'ChartCanvas',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        return ['line', 'pie'].includes(type)
      }
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // shallowRef should be used forChart.JS instance
    // issue link: https://github.com/chartjs/Chart.js/issues/9504
    const chartRef = shallowRef(null);
    const canvasRef = ref(null);

    const renderChart = () => {
      const canvas2dContext = canvasRef.value.getContext('2d');

      chartRef.value = new Chart(canvas2dContext, {
        type: props.type,
        data: props.data,
        options: props.options,
      });
    }

    onMounted(() => {
      renderChart();
    });

    // Watches only the chart's first dataset's data
    watch(
      () => props.data,
      (
        newPropData,
        oldPropData,
      ) => {
        const chart = toRaw(chartRef.value);

        if (!chart) {
          return
        }

        let shouldUpdate = false

        if (newPropData) {
          const newData = [ ...newPropData.datasets[0].data ];
          const oldData = [ ...oldPropData.datasets[0].data ];

          if (newData && !isEqual(oldData, newData)) {
            chart.config.data.datasets[0].data = newData;
            shouldUpdate = true
          }
        }

        if (shouldUpdate) {
          nextTick(() => chart.update())
        }
      },
      { deep: true }
    )

    return {
      canvasRef,
    }
  }
})
