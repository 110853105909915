















































import {
  defineComponent,
  computed,
  onMounted,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useBBXHistoryData } from '@cellar-services/composables/useBBX/useBBXHistoryData';
import ChartCanvas from '@cellar-services/components/organisms/ChartCanvas.vue';
import debounce from 'lodash-es/debounce';

export default defineComponent({
  name: 'HistoryGraph',
  components: {
    SkeletonLoader,
    ChartCanvas
  },
  props: {
    itemSku : {
      type: String,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const { $fc } = useContext();
    const { loading, error, data, getBBXHistoryData } = useBBXHistoryData({
      productSku: route.value.query?.sku as string || props.itemSku,
      threshold: Number(route.value.query?.threshold) || 20
    });

    const formatDateForLabel = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const dayWithSuffix = day + (day > 3 && day < 21 ? 'th' : ['th', 'st', 'nd', 'rd'][day % 10] || 'th');
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();

      return `${dayWithSuffix} ${month} ${year}`;
    }

    // We can't use default axes because we can't customize them to our designs
    // by default, ChartJS will display all the points in y axes (time scale),
    // but we need to display only the first and last years
    // to implement this, we create a custom axes
    const startYear = computed(() => {
      if (!data.value.length) return '';

      return data.value[0].Key.substring(0, 4) || '';
    });
    const endYear = computed(() => {
      if (!data.value.length) return '';

      return Number(data.value[data.value.length - 1].Key.substring(0, 4)) || '';
    });

    const chartLabels = computed(() => {
      return data.value.map((item) => item.Key);
    });

    const chartDatasets = computed(() => {
      return data.value.map((item) => item.Value);
    });

    // Next options are for the ChartJS cusomisation
    // https://www.chartjs.org/docs/3.9.1/charts/line.html
    // hint: try to use Bart/Copilot to get the best suggestions as docs are quite complex
    const genericOptions = {
      layout: {
        // layout docs: https://www.chartjs.org/docs/3.9.1/configuration/layout.html
        // by default autoPadding true, and addes padding to the chart, even if we set padding to 0
        autoPadding: false,
        padding: {
          top: 22,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      // https://www.chartjs.org/docs/3.9.1/axes/cartesian/linear.html#linear-axis
      // remove x and y axes
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
          beginAtZero: route.value?.query?.beginAtZero ? true : false,
        }
      },
      plugins: {
        // https://www.chartjs.org/docs/3.9.1/configuration/tooltip.html#tooltip-configuration
        tooltip: {
          // by default it's true, and displays color of the line as a swatch in the tooltip
          displayColors: false,
          titleFont: {
            // Price font size
            size: 16
          },
          bodyFont: {
            // Date font size
            size: 14
          },
          // Tooltip padding
          padding: 8,
          // https://www.chartjs.org/docs/3.9.1/configuration/tooltip.html#tooltip-callbacks
          callbacks: {
            // return formatted price in title slot
            title: (context) => {
              return $fc(context[0].raw);
            },
            // return formatted date in label slot
            label: (context) => {
              return formatDateForLabel(context.label);
            },
          }
        }
      }
    };

    const chartData = computed(() => {
      return {
        labels: chartLabels.value,
        // https://www.chartjs.org/docs/3.9.1/charts/line.html#dataset-properties
        // dataset configuration
        datasets: [
          {
            data: chartDatasets.value,
            fill: 'origin',
            backgroundColor: '#C7A67D',
            borderColor: '#C7A67D',
            borderJoinStyle: 'round',
            pointBackgroundColor: '#FFFFFF',
            pointBorderColor: '#C7A67D',
            borderWidth: 1,
            pointRadius: 3,
            pointHitRadius: 8,
            pointBorderWidth: 1,
          }
        ]
      };
    });

    const loadedWithoutErrors = computed(() => {
      return !loading.value && !error.value;
    });

    const hasYearsAxis = computed(() => {
      return startYear.value && endYear.value;
    });

    onMounted(() => {
      getBBXHistoryData();
    });

    return {
      loading,
      error,
      data,
      chartData,
      genericOptions,
      startYear,
      endYear,
      chartDatasets,
      loadedWithoutErrors,
      hasYearsAxis,
      route
    }
  }
});
