import { computed, ref } from '@nuxtjs/composition-api';
import type { HistoryEntryInterface, HistoryPayloadInterface } from '@cellar-services/types';
import { useBBXHistoryDataStore } from '@cellar-services/stores/bbx/bbx-history-data';
import { storeToRefs } from 'pinia';

export const useBBXHistoryData = (payload: HistoryPayloadInterface) => {
  const loading = ref<boolean>(false);
  const error = ref<string>('');
  const store = useBBXHistoryDataStore();
  const { findHistory } = storeToRefs(store);

  const getBBXHistoryData = async () => {
    loading.value = true;

    try {
      await store.fetchHistory(payload);
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  };

  const data = computed<HistoryEntryInterface[]>(() => findHistory.value(payload))

  return {
    loading,
    error,
    data,
    getBBXHistoryData,
  };
};
