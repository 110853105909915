import { defineStore } from 'pinia';
import { ref, useContext, computed } from '@nuxtjs/composition-api';
import type { HistoryEntryInterface, HistoryPayloadInterface} from '@cellar-services/types';
import { Logger } from '~/helpers/logger';

interface State {
  [key: string]: HistoryEntryInterface[];
}

interface LoadingState {
  [key: string]: boolean;
}

export const useBBXHistoryDataStore = defineStore('bbx-history-data-store', () => {
  const { $vsf } = useContext();
  const state = ref<State>({});
  const loadingState = ref<LoadingState>({});

  const generateCacheKey = (payload: HistoryPayloadInterface) => `${payload.productSku}_${payload.threshold}`;

  const findHistory = (payload: HistoryPayloadInterface): HistoryEntryInterface[] => {
    const cacheKey = generateCacheKey(payload);

    return state.value[cacheKey] || [];
  };

  const setLoadingState = (cacheKey: string, status: boolean) => {
    loadingState.value = {
      ...loadingState.value,
      [cacheKey]: status,
    };
  };

  const fetchHistory = async (payload: HistoryPayloadInterface, hardRefresh = false): Promise<void> => {
    const cacheKey = generateCacheKey(payload);

    if (loadingState.value[cacheKey] || (state.value.hasOwnProperty(cacheKey) && !hardRefresh)) {
      return;
    }

    setLoadingState(cacheKey, true);

    const response = await $vsf.$cellarServices.api.getBbxOrderHistory(payload) as {
      status?: number;
      message?: string;
      data?: HistoryEntryInterface[];
    };

    if (response?.status >= 300) {
      Logger.error(response?.message || 'Unable to get history data, please try again.')
    } else {
      setLoadingState(cacheKey, false);

      state.value = {
        ...state.value,
        [cacheKey]: response.data,
      };
    }
  };

  return {
    state,
    fetchHistory,
    findHistory: computed(() => findHistory),
  };
});
